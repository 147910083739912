export const ru = {
  header: {
    address: "город Ташкент, улица Дийдор - 71",
  },
  menu: {
    about: "Об университете",
    univer: "Университет",
    campus: "Учебный корпус",
    structure: "Структура",
    faculty: "Факультеты",
    bakalavr: "Бакалавриат",
    magistr: "Магистратура",
    admis: "Приемная комиссия",
    direction: "Направления",
    condition: "Условия приема",
    admission: "Квота приема",
    relocation: "Перевод",
    contract: "Сумма контракта",
    contact: "Контакты",
    faq: "FAQ",
    about_f: "Об университете",
    grant: "Грант",
    conf: "Конференция",
    journal: "Журнал",
    gallery: "Галерея",
    promo: "Приемная акция",
    vaucher: "Ваучер приёма",
  },
  double: `ПРОГРАММА ДВОЙНОГО ДИПЛОМА`,
  double_title: "МЕЖДУ UMFT И УНИВЕРСИТЕТОМ FAR EAST UNIVERSITY",

  grant: {
    discount: "Скидка грант",
    direction: "Направление",
    title: "Итоги финального этапа конкурса грантов",
    50: `Список 50-процентных грантополучателей`,
    75: `Список 75-процентных грантополучателей`,
    100: `Список 100-процентных грантополучателей`,
    25: `Список участников, получивших 25% скидку без гранта`,
    name: "ФИО",
    ball: "Бал",
    kurs: "Курс",
    group: "Группа",
    m_title:
      "Список участников приемной кампании. <br > <span>(разыгрываются Монца и другие ценные подарки)</span>",
    text: "Вы также можете быть в числе участников, полностью оплатить контракт на 2024-2025 учебный год и стать участником акции. <br > для вопросов: <strong>+998 (55) 506 88 88</strong> позвоните по номеру",
  },
  certdownload: "Файл сертификата",
  vaucher: "Номер ваучера (если имеется)",
  nav: {
    admit: "Подать заявление",
    relocation: "Перевод",
  },
  about: {
    item_1: {
      title: "Образование для всех",
      text: "Выявление, поддержка и предоставление грантов для талантливой молодежи",
    },
    item_2: {
      title: "Гибридное образование",
      text: "Дистанционное обучение в сочетании с учебным планом одновременно международных сертифицированных учебных курсах",
    },
    item_3: {
      title: "Наука",
      text: "Обеспечить эффективную интеграцию науки, образования и производства в кооперации",
    },
    item_4: {
      title: "Международный стандарт",
      text: "Подготовка высококвалифицированных кадров с современными знаниями на основе международных стандартов образования",
    },

    title: "О нас",
    text: `<h2>Учебный корпус и условия</h2>
        <p>Преподавание в университете ведут ведущие профессора и преподаватели в аудиториях, оборудованных современными технологиями</p>
        <p>В учебном корпусе имеются лекционные и практические учебные аудитории, компьютерные лаборатории, информационно-ресурсный центр и электронная библиотека, оснащенные современными компьютерами</p>
        <p>Здание университета полностью покрыто сетью WI-FI, к которой студенты имеют свободный доступ</p>`,
  },
  welcome: {
    title: "Наш университет является",
    slogan: "ЛУЧШИМ ПАРТНЕРОМ ДЛЯ ВАШЕГО БУДУЩЕГО",
    text: `Подготовка высококвалифицированных специалистов в соответствии с требованиями рынка труда на основе международного опыта и передовых стандартов высшего образования `,
    more: "Подробнее ",
    promo: "Список участников",
  },
  welcome_2: {
    title: "Diqqat tanlov!!!",
    slogan: "180 ta grant o`rinlari",
    text: `University of Management and Future Technologies universiteti 2024/2025 o'quv yili uchun grant o'rinlariga abiturientlarning qabul tanlovini e'lon qiladi.`,
    more: "Tanlovda qatnashish",
    result: "Результаты гранта",
  },
  welcome_3: {
    title: "DIQQAT AKSIYA!!!",
    slogan: "Talaba bo‘ling, avtomobil sohibiga aylaning,",
    text: `University of management and future technologies universiteti 2024/2025 oquv yili uchun qabul aksiyasini e'lon qiladi`,
    more: "Aksiya shartlari",
  },
  programlist: {
    title: "Факультеты университета",
    text: "В университете имеется три факультета, которые готовят бакалавров и магистров в области инженерии информационных технологий, экономики и управления бизнесом, педагогики и филологии.",
  },
  mission: {
    title: "Наши цели и задачи",
    subtitle: "Наша цель",
    text: `<p>Быть одним из вузов с высокими рейтинговыми показателями в сфере высшего образования, предоставляя качественное образование на уровне мировых стандартов, готовя конкурентоспособных, современных специалистов</p>`,
  },
  price: {
    title: "Список направлений университета",
    subtitle: `Направление образования и специальности`,
    currency: `млн. сум / год`,
  },
  partners: {
    tuit: "Ташкентский Университет Информационных Технологий",
    pedagogika: "ТАШКЕНТСКИЙ ГОСУДАРСТВЕННЫЙ ПЕДАГОГИЧЕСКИЙ УНИВЕРСИТЕТ",
    moliya: "ТАШКЕНТСКИЙ ФИНАНСОВЫЙ ИНСТИТУТ",
    guldu: "Гулистанский Государственный Университет",
    nammti: "Наманганский Инженерно-Технологический институт",
    digital: "ЦЕНТР ИССЛЕДОВАНИЙ ЦИФРОВОЙ ЭКОНОМИКИ",
    avloniy: "Национальный исследовательский институт имени А.Авлоний",
    center: "Центр развития цифровых образовательных технологий",
    title: "Партнёры",
  },
  footer: {
    text: "Поднимитесь с нами на вершину науки и станьте квалифицированным специалистом",
    lic: "",
  },
  modal: {
    title: "Отправить свой вопрос",
    name: "Представьтесь",
    question: "Текст вашего вопроса",
    send: "Отправить",
    thnks: "Ваш вопрос успешно отправлено.",
  },
  breadcrumb: {
    home: "Главная страница ",
  },
  contact: {
    title: "Контакты",
    phone: "Номер телефона",
    address: {
      title: "Адрес",
      value: "Город Ташкент, улица Дийдор - 71",
      value_2:
        "город Ташкент, улица Богибостон, дом 3А (район Ракат, Текстиль)",
    },
    email: "Электронная почта",
    main_place: "Главное здание",
    second_place: "2й здание",
    second_text:
      "Подъехав ко 2-му корпусу университета, выйдите на остановке <u>«Текстиль Комбинати»</u> вышеуказанных автобусов.",
  },
  bus: {
    title: "Автобусные остановки",
    station: "Название остановки",
    number: "Номер автобуса",
    direction: "Диапазон движения",
    time: "Время",
    line_1: "Остановка завода Алгоритм",
    mini: "Остановки маршрута",
    mininumber: "Номер Маршрутки",
  },
  page: {
    faq: "ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ",
    question: "Отправить вопрос ",
  },
  report: {
    title: "Информация о студенте",
    name: "Ф.И.О",
    lvl: "Тип обучение",
    studyType: "Вид обучение",
    year: "Период обучения",
    course: "Курс",
    courseCur: "курс",
    info: "Информация о контракте",
    direction: "Направление обучение",
    quantity: "Номер договора",
    date: "Дата контракта",
    summa: "Сумма контракта",
    copy: "Копии контракта",
    download: "Скачать",
    sum: "сум",
  },
  admit: {
    dtm: "DTM ID (если при наличии)",
    sale: "Загрузите сертификат на скидку (если применимо)",
    studyType_off: "Очное",
    studyType_on: "Заочное",
    title: "Подать заявление",
    success_title: "Уважаемый абитуриент!!!",
    success_text:
      "Ваша заявка и приложенные документы успешно отправлены в Приемную комиссию UMFT. В ближайшее время вы получите смс-уведомление о том, что приемная комиссия приняла ваши документы",
    step_1: "Личные данные",
    step_2: "Данные об образовании",
    step_3: "Подача заявление",
    lname: "Фамилия",
    name: "Имя",
    secondname: "Отчество",
    gender: "Пол",
    male: "Мужчина",
    female: "Женщина",
    phone: "Номер телефона",
    email: "Электронная почта",
    country: "Гражданство",
    selectCountry: "Выберите страну",
    passport: "Номер Паспорта / ID-карты",
    bdate: "Дата рождения",
    province: "Область ",
    selectProvince: "Выберите из списка",
    district: "Город/район",
    selectDistrict: "Выберите район",
    address: "Адрес проживания",
    rectype: "Тип приёма",
    selectList: "Выберите из списка",
    studyName: "Где он закончил??",
    studyNameShow: "Учреждение которую вы закончили",
    studyId: "Серия и номер сертификата/диплома",
    studyShow: "Сертификат/диплом",
    foreign: "Изучал иностранный язык",
    studyPart: "Тип полученного образования",
    direction: "Выбранное направление",
    directionShow: "Направление",
    language: "Язык обучения",
    studyType: "Форма обучения",
    studyDocument: "Загрузите файл сертификата/диплома",
    studyDocumentShow: "Файлы сертификатов/дипломов",
    studyDocumentError: "Размер файла не должен превышать 4 МБ",
    academ: "Загрузить файл академической справки/стенограммы",
    academError:
      "Размер академического справочного файла не должен превышать 4 МБ.",
    academShow: "Академическая справка/файлы транскриптов",
    pasImg: "Загрузите файл паспорта",
    pasImgError: "Размер файла паспорта не должен превышать 4 МБ.",
    pasShow: "Паспортные файлы",
    img: "Загрузите файл изображения",
    imgShow: "Файлы изображений",
    imgError: "Размер файла изображения не должен превышать 4 МБ.",
    imgType:
      "В файл изображения можно загрузить только файлы форматов img, png",
    aggree: "Достоверность и достоверность информации подтверждаю",
    prev: "Назад",
    next: "Далее",
    code: "Подвердите код",
    send: "Отправить",
    save: "Сохранить",
    info: "отправлено код",
    ok: "Подвердить",
    cur: "шт.",
    exam: "Время экзамена",
    examtitle: "Вступительный экзамен",
    sert: "Til bilish sertifikatlari (agar mavjud bo`lsa)",
    sertShow: "Til bilish sertifikatlari",
    choose_direction: "Выберите направление",
    contract_salary: "Стоимость обучения",
    private_info: "Персональные данные",
    contact_info: "Контактная информация",
    study_info: "Образовательная информация",
    choose_studyType: `В какой форме вы хотите учиться?`,
    choose_language: `На каком языке вы хотите учиться?`,
    choose_foreign: `Какой иностранный язык вы изучали?`,
    choose_exam: `Выберите дату вступ. экзамена`,
  },
};
